.view-more-link{
    display: flex;
    justify-content: right;
    padding-right: 10px;
}

.view-more-link svg{
    font-size: 12px;
}

.view-more-link p{
    color: rgb(192, 192, 192) !important;
}

.view-more-link p:hover{
    text-decoration: solid underline !important; 
    color: rgb(255, 255, 255) !important;
}

.mini-title{
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 1.5rem !important;
}


.draft-card{
    display: flex;
    padding: 0px 20px;
}

.rank-mock-mini{
    display: flex;
    padding: 5px 20px;
}

.draft-card-text{
    font-size: 1rem !important;
    display: inline;
    color: white;
    text-decoration: underline 1px dotted rgb(121, 121, 121);
}

.draft-card-number{
    display: flex;
    justify-content: left;
    font-size: 1rem !important;
    padding-top: 5px;
}

.team-logo{
    width: 50px;
    margin-top: -10px;
    display: inline;
}
.via-draft{
    color: gray;
    position: relative;
    padding-left: 60px;
    top: -20px;
}

.via-draft-long{
    color: gray;
    display: inline;
    padding-left: 10px;
}

.inline-draft-text{
    display: flex;
    padding: 5px;
}

.round-number{
    display: flex;
    justify-content: center;
    font-size: 1.2rem !important;
    padding-bottom: 20px;
    padding-top: 30px;
    font-weight: 900 !important;
    color: rgb(212, 212, 212) !important;
}

.rank-mock-divider{
    width: 2px;
    height: 2px;
    background-color: gray;
    margin: 9px 6px;
    border-radius: 50%;
}

.rank-mock-title{
    font-size: 0.9rem !important;
}

.rank-mock-title:hover{
    text-decoration: solid underline !important;
}

.rank-mock-subtitle{
    display: flex;
    justify-content: left;
    font-size: 0.8rem !important;
    color: gray !important;
}

.trending-player-card{
    display: flex;
    padding: 10px 20px;
}

.rank-increase{
    color: rgb(131 255 131) !important;
    display: flex;
}

.rank-drop{
    color: rgb(255, 81, 81) !important;
    display: flex;
}

.trending-player-rank{
    padding-right: 10px;
}

.trending-player-name:hover{
    text-decoration: solid underline !important;
}


.trending-player-card{
    display: flex;
    padding: 10px 20px;
}

.trending-players-sub-heading{
    color: gray !important;
    display: flex;
    justify-content: center;
    font-size: 0.8rem !important;
    padding-bottom: 10px;
}