#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 6rem;
}

.footer {
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
}

.copy-right {
  padding-bottom: 20px;
  padding-top: 10px;
  color: white !important;
}

.footer-nav {
  display: flex;
  justify-content: center;
}

.footer-split {
  position: flex;
  width: 1px;
  height: 0.5rem;
  background-color: rgb(255, 255, 255);
  margin: 0.4rem 10px;
}

.footer a:hover {
  color: rgb(255, 255, 255) !important;
  text-decoration: underline !important;
  text-underline-offset: 4px;
}

@media only screen and (max-width: 600px)  {
  .footer-split {
    display: none;
  }

  .footer-link {
    padding: 2px;
    font-size: 12px;
  }
}