.inner-table-title {
    position: absolute;
    z-index: 10;
    font-size: 1.7rem !important;
    margin-top: 10px !important;
}

.rank-stat {
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    border: 1px solid #333;
    text-align: center;
}

.rank-stat-title {
    font-size: 0.8rem !important;
}

.rank-stat-data {
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
    .inner-table-title {
        position: relative;
        display: block;
        padding: 10px;
        font-size: 1.7rem !important;
        text-align: center !important;
    }
}

.embeded-video {
    position: relative;
    padding: 15px;
}

.con-no-bot-pad {
    padding-bottom: 0px !important;
    height: 100%;
}


.draft-number-group {
    color: white;
    padding: 0px 10px !important;
}

.draft-pick-number {
    font-size: 1.2rem !important;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.draft-pick-text {
    font-size: 0.8rem !important;
}

.draft-odds {
    padding: 10px 10px !important;
}

.draft-odds-data {
    outline: 1px solid rgb(126, 126, 126);
    margin: 10px;
}

.draft-pick-logo .team-logo {
    width: 3rem;
    display: inherit;
    padding-top: 3px;
    left: -6px;
    position: relative;
}

.draft-odds-title {
    text-align: center;
    padding-bottom: 10px;
}

.player-draft-odds-container {
    position: relative !important;
}

.player-draft-pick-row {
    display: flex !important;
    position: relative !important;
    padding-top: 10px;
    align-items: center;
    align-items: center;
    justify-content: center;
}

.out-of-top-96{
    font-size: 1.1rem !important;
    font-weight: 1000;
    padding-right: 10px;
    margin-top: -10px;
}

@media only screen and (max-width: 1150px) and (min-width: 900px) {
    .draft-pick-number {
        font-size: 0.8rem !important;
        text-align: center;
        padding-top: 10px;
    }

    .draft-pick-text {
        font-size: 0.8rem !important;
    }

    .draft-number-group {
        padding: 5px !important;
    }
}