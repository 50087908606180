a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

a {
  color: #333;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 500;
}

.top-container {
  margin-top: 40px;
}

.container {
  background-color: #252526;
  padding-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 10px !important;
  position: relative;
}

.page-title {
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 2rem !important;
}

.title-underline {
  border-bottom: 2px solid #ffffff;
  width: 10%;
  margin: 10px auto;
  margin-top: 0px !important
}

.MuiTypography-root {
  color: white;
}

.MuiTypography-root a {
  color: rgb(196, 196, 196);
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  text-underline-offset: 4px;
}

.link-default {
  color: rgb(196, 196, 196);
  -webkit-text-decoration: underline dotted !important;
  text-decoration: underline dotted !important;
  text-underline-offset: 4px !important;
}

.link-default:hover {
  color: rgb(128, 128, 128) !important;
  text-decoration: underline !important;
  text-underline-offset: 4px;
}

.MuiPopover-paper {
  background-color: #171f22 !important;
  color: white !important;
}

.sub-header-text {
  color: white !important;
}

@media only screen and (max-width: 900px) {
  .css-1qqnaz8-MuiGrid-root {
    width: calc(100% + 8px) !important;
    margin-left: 8px !important;
  }
}

.draft-year-container{
  margin-top: 10px;
}