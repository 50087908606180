.card-wrapper {
    display: inline-block;
    position: relative;
    cursor: pointer;
    /* width: 80%;
    margin: 0px 10%; */
    background-color: #1e1e1e;
}

.overlay {
    position: absolute;
    background-image: linear-gradient(to top, #1e1e1e, #ffff0000);
    bottom: 0;
    left: 0;
    right: 0;
    height: 45%;

}

.first-name {
    position: absolute;
    bottom: 30px;
    left: 10px;
    color: white;
    font-size: 1.7rem !important;
}

.last-name {
    position: absolute;
    bottom: 0px;
    left: 10px;
    font-size: 1.7rem !important;
    color: white;
}

.stat-title {
    color: rgb(173, 173, 173);
    font-size: 0.9rem !important;
}

.stat-value {
    font-size: 1.2rem !important;
    color: white;
}

.ranking-name {
    text-align: right;
}

.ranking-number {
    text-align: right;
}

.player-card:hover {
    box-shadow: 0px 8px 10px rgb(71, 71, 71);
}

.player-card {
    background-color: #1e1e1e !important;
    border: solid 1px #678651;
    height: 100%;
    position: relative;
}


.nhl-bottom-banner {
    width: 100% !important;
    top: 5px !important;
    color: white;
    text-align: center;
    padding: 2px;
    display: inline;
}

.nhl-bottom-banner-logo {
    width: 18%;
    margin-left: 2%;
}

.player-nhl-draft{
    width: 100% !important;
    bottom: -6px !important;
    color: white;
    text-align: center;
    padding: 2px;
    display: inline-flex;
    position: relative;
}

.draft-title{
    width: 80%;
}

.nhl-team-name {
    font-size: 1rem !important;
}

.card-ranking {
    position: absolute;
    background-color: #678651;
    color: white;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 5px;
    border-bottom-right-radius: 5px;
}

.rank-title {
    font-size: 0.75rem;
}

.rank-value {
    font-size: 1.1rem !important;
}

.odds-value {
    font-size: 0.9rem !important;
}

.name-box {
    position: absolute;
    bottom: -50px;
    width: 100%;
}

.card-data {
    margin-top: 32px !important;
    background-color: #1e1e1e;
}

.default-image {
    /* background-color: white; */
    width: 100%;
}

.birthplace-value {
    font-size: 1.2rem !important;
    color: white;
}

.short-view-spacer {
    height: 70px;
}

.miniview-data {
    display: flex;
    margin-top: 36px !important;
}

.miniview-pos {
    font-size: 0.8rem !important;
}

.miniview-dob {
    font-size: 0.8rem !important;
    padding-left: 10px;
}

@media only screen and (max-width: 600px) {
    .first-name {
        position: absolute;
        bottom: 20px;
        left: 10px;
        color: white;
        font-size: 1.3rem !important;
    }

    .last-name {
        position: absolute;
        bottom: 0px;
        left: 10px;
        font-size: 1.3rem !important;
        color: white;
    }

    .miniview-data {
        display: flex;
        margin-top: 36px !important;
        font-size: 12px !important;
        margin-left: -5px !important;
    }

    .miniview-pos {
        font-size: 0.7rem !important;
    }

    .miniview-dob {
        font-size: 0.7rem !important;
    }
}