.drop-down-label{
    color: white !important;
    font-size: 1.2rem;
}

.drop-down{
    background-color: #252526 !important;
    border: 1px solid #ffffff !important;
    color: white !important;
    font-size: 1.2rem;
    width: 100%;
}


.MuiSelect-icon{
    color: white !important;
}

.clear-btn{
    color: white !important;
    font-size: 1.2rem;
    border-color: #ffffff !important;
    position: absolute !important;
    bottom: 0 !important;
    height: 53% !important;
}

.clear-btn-container{
    position: relative;
}

@media only screen and (max-width: 600px)  {
    .clear-btn{
        color: white !important;
        font-size: 1.2rem;
        border-color: #ffffff !important;
        position: absolute !important;
        height: 30px !important;
        padding: 10px !important;
        position: relative;
    }
    .clear-btn-container{
        height: 50px;
    }
  }