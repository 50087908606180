.nav-bar nav {
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 24px;
  width: 100%;
  margin-bottom: 10px;
  margin-left: -10px;
}

.nav-bar a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

.nav-bar a {
  color: #333;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 500;
}

.nav-bar button {
  height: 100% !important;
  margin-top: 0 !important;
  color: rgb(187, 187, 187);
}
.nav-bar button:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}



.active {
  color: white !important;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 600 !important;
}

.underline {
  height: 1px;
  background-color: #678651;
  width: 90%;
  margin-top: 16px;
  position: absolute !important;
}

.nav-btns{
  padding-left: 15px;
}