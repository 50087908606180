.rank-mock-card{
    margin-bottom: 10px;
    background-color: #252526 !important;
    box-shadow: none !important;
}


.rank-mock-header a{
    color:rgb(245, 245, 245) !important;
}

.rank-mock-header a:hover{
    color:rgb(209, 209, 209) !important;
    text-decoration: underline !important;
    text-underline-offset: 4px;
}


.rank-mock-subheader{
    display: flex;
}

.rank-mock-subheader a{
    color: rgb(196, 196, 196) !important;
    text-decoration: underline dotted !important;
    text-underline-offset: 4px;
}

.rank-mock-subheader p{
    color: rgb(196, 196, 196) !important;
}

.rank-mock-subheader a:hover{
    color: rgb(128, 128, 128) !important;
    text-decoration: underline !important;
    text-underline-offset: 4px;
}

.sub-heading-divider{
    width: 4px;
    height: 4px;
    background-color: gray;
    margin: 10px 8px;
    border-radius: 50%;
}


.rank-mock-card-divider{
    width: 100%;
    height: 1px;
    background-color: rgb(128, 128, 128);
    margin: 10px 0px;
}