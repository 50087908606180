.team-page-header{
    display: flex;
    position: relative;
    justify-content: center;

}

.team-page-header-title{
    display: flex;
    text-align: center;
    padding-top: 10px;
    font-size: 5.5rem !important;
    font-weight: 600;
    position: relative; 
}

.team-page-header-title .team-name{
    font-size: 2.5rem !important;
}

.team-page-header-title .team-logo{
    width: 4.5rem;
    display: inherit;
    padding-top: 3px;
    left: -6px;
    position: relative;
    top: 3px;
}