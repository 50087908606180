.search-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 20px;
    color: white !important;
}

.search-bar label {
    color: #678651 !important;
}

.search-bar input {
    color: white !important;
}
.search-bar fieldset {
    border-color: #67865188!important;
}


.autocomplete-search-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 20px;
    color: white !important;
    margin-top: 10px;
}

.autocomplete-search-bar svg{
    color: white !important;
}


.MuiAutocomplete-noOptions {
    display: none !important;
}
