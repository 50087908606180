.player-rank-mock-card {
    margin-bottom: 10px;
    background-color: #252526 !important;
    box-shadow: none !important;
}


.player-rank-mock-header a {
    color: rgb(245, 245, 245) !important;
}

.player-rank-mock-header a:hover {
    color: rgb(209, 209, 209) !important;
    text-decoration: underline !important;
    text-underline-offset: 4px;
}


.player-rank-mock-subheader {
    display: flex;
}

.player-rank-mock-subheader a {
    color: rgb(196, 196, 196) !important;
    text-decoration: underline dotted !important;
    text-underline-offset: 4px;
}

.player-rank-mock-subheader p {
    color: rgb(196, 196, 196) !important;
}

.player-rank-mock-subheader a:hover {
    color: rgb(128, 128, 128) !important;
    text-decoration: underline !important;
    text-underline-offset: 4px;
}

.player-rank-mock-card-rank-box {
    margin-right: 10px;
    text-align: center;
    background-color: rgba(125, 253, 131, 0.603);

}

.player-rank-title{
    padding-top: 5px !important;
}

.player-rank-value{
    font-size: 1.5rem !important;
}

.player-rank-mock-card-content {
    display: flex;
}

.sub-heading-divider {
    width: 4px;
    height: 4px;
    background-color: gray;
    margin: 10px 8px;
    border-radius: 50%;
}


.player-rank-mock-card-divider {
    width: 100%;
    height: 1px;
    background-color: rgb(128, 128, 128);
    margin: 10px 0px;
}