.recharts-cartesian-axis-tick {
    font-size: 0.8rem;
    font-family: Roboto, sans-serif;
}

.recharts-legend-item {
    font-size: 0.8rem;
    font-family: Roboto, sans-serif;
}

.graph-container {
    width: 100%;
    height: 100%;
    background-color: #252526;
    border-radius: 10px;
}

.graph-container-parent {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 3px;
}

.recharts-default-legend {
    margin: 0 auto;
    display: block;
}

.tooltip-box{
    background-color: #1e1e1e;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #252526;
    padding: 0px 20px 10px;
}

.tool-tip-title{
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    display: block;
    margin: -10px -5px !important;
    padding: 0px !important;
}

.tool-tip-name{
    display: inline-block;
    font-size: 1rem;
}

.tool-tip-value{
    font-size: 1rem;
    font-weight: 900;
    display: inline-block;
}