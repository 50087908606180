.draft_year{
    font-size: 1.5rem !important;
    padding-top: 10px;
}

.lesser_pick{
    font-size: 1.5rem !important;
    padding-top: 10px;
    display: flex;
    padding-left: 10px;
    border-bottom: 1px dashed #333;
}

.lesser_pick:hover {
    color: rgb(255, 255, 255) !important;
    background-color: #333 !important;
}

.lesser_pick:hover > .player-name{
    text-decoration: dotted underline #fff !important;
}

.lesser_pick > .sub-info{ 
    color: rgb(196, 196, 196);
}

.lesser_pick > .player-name{
    color: white;
    padding-left: 10px;
}

.draft_grade{
    font-size: 1.5rem !important;
    font-weight: bold;
}

.grade_text{
    display: inline;
    color: #dddddd;
    font-size: 16px;
}